// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agua-js": () => import("./../../src/pages/agua.js" /* webpackChunkName: "component---src-pages-agua-js" */),
  "component---src-pages-aguadecoco-js": () => import("./../../src/pages/aguadecoco.js" /* webpackChunkName: "component---src-pages-aguadecoco-js" */),
  "component---src-pages-alcool-js": () => import("./../../src/pages/alcool.js" /* webpackChunkName: "component---src-pages-alcool-js" */),
  "component---src-pages-bazar-js": () => import("./../../src/pages/bazar.js" /* webpackChunkName: "component---src-pages-bazar-js" */),
  "component---src-pages-bebidas-js": () => import("./../../src/pages/bebidas.js" /* webpackChunkName: "component---src-pages-bebidas-js" */),
  "component---src-pages-cafe-js": () => import("./../../src/pages/cafe.js" /* webpackChunkName: "component---src-pages-cafe-js" */),
  "component---src-pages-cerveja-js": () => import("./../../src/pages/cerveja.js" /* webpackChunkName: "component---src-pages-cerveja-js" */),
  "component---src-pages-chapronto-js": () => import("./../../src/pages/chapronto.js" /* webpackChunkName: "component---src-pages-chapronto-js" */),
  "component---src-pages-conservas-js": () => import("./../../src/pages/conservas.js" /* webpackChunkName: "component---src-pages-conservas-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-desodorante-js": () => import("./../../src/pages/desodorante.js" /* webpackChunkName: "component---src-pages-desodorante-js" */),
  "component---src-pages-destilado-js": () => import("./../../src/pages/destilado.js" /* webpackChunkName: "component---src-pages-destilado-js" */),
  "component---src-pages-docesbisc-js": () => import("./../../src/pages/docesbisc.js" /* webpackChunkName: "component---src-pages-docesbisc-js" */),
  "component---src-pages-farinhagraos-js": () => import("./../../src/pages/farinhagraos.js" /* webpackChunkName: "component---src-pages-farinhagraos-js" */),
  "component---src-pages-higiene-js": () => import("./../../src/pages/higiene.js" /* webpackChunkName: "component---src-pages-higiene-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-isotonico-js": () => import("./../../src/pages/isotonico.js" /* webpackChunkName: "component---src-pages-isotonico-js" */),
  "component---src-pages-leite-js": () => import("./../../src/pages/leite.js" /* webpackChunkName: "component---src-pages-leite-js" */),
  "component---src-pages-leitepoachoc-js": () => import("./../../src/pages/leitepoachoc.js" /* webpackChunkName: "component---src-pages-leitepoachoc-js" */),
  "component---src-pages-limpeza-js": () => import("./../../src/pages/limpeza.js" /* webpackChunkName: "component---src-pages-limpeza-js" */),
  "component---src-pages-limpezaderoupa-js": () => import("./../../src/pages/limpezaderoupa.js" /* webpackChunkName: "component---src-pages-limpezaderoupa-js" */),
  "component---src-pages-massasmolhos-js": () => import("./../../src/pages/massasmolhos.js" /* webpackChunkName: "component---src-pages-massasmolhos-js" */),
  "component---src-pages-mercearia-js": () => import("./../../src/pages/mercearia.js" /* webpackChunkName: "component---src-pages-mercearia-js" */),
  "component---src-pages-oleos-js": () => import("./../../src/pages/oleos.js" /* webpackChunkName: "component---src-pages-oleos-js" */),
  "component---src-pages-papelhig-js": () => import("./../../src/pages/papelhig.js" /* webpackChunkName: "component---src-pages-papelhig-js" */),
  "component---src-pages-pastadente-js": () => import("./../../src/pages/pastadente.js" /* webpackChunkName: "component---src-pages-pastadente-js" */),
  "component---src-pages-pet-js": () => import("./../../src/pages/pet.js" /* webpackChunkName: "component---src-pages-pet-js" */),
  "component---src-pages-products-js": () => import("./../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-refrigerante-js": () => import("./../../src/pages/refrigerante.js" /* webpackChunkName: "component---src-pages-refrigerante-js" */),
  "component---src-pages-sabonete-js": () => import("./../../src/pages/sabonete.js" /* webpackChunkName: "component---src-pages-sabonete-js" */),
  "component---src-pages-suco-js": () => import("./../../src/pages/suco.js" /* webpackChunkName: "component---src-pages-suco-js" */),
  "component---src-pages-temperos-js": () => import("./../../src/pages/temperos.js" /* webpackChunkName: "component---src-pages-temperos-js" */),
  "component---src-templates-blog-js": () => import("./../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

